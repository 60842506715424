import { useParams } from "react-router";
import { Home, Termine } from ".";
import Texte from './AngebotTexte';

export function Angebote(props) {
    const { angebot } = useParams();
    if (['stillberatung', 'stillvorbereitung', 'trageberatung', 'elterncafe', 'babymassage', 'stillgruppe', 'waldworkshop'].indexOf(angebot) < 0) return <Home />

    const { isMobile } = props;
    let title = ''
    let text = ''
    let image = ''

    switch (angebot) {
        case 'stillberatung':
            title = 'Stillberatung';
            break;
        case 'stillvorbereitung':
            title = 'Stillvorbereitung';
            break;
        // case 'outdoorfit':
        //     title = 'Outdoor-Fit mit Kind';
        //     break;
        case 'trageberatung':
            title = 'Trageberatung';
            break;
        case 'elterncafe':
            title = 'Elterncafé';
            break;
        case 'babymassage':
            title = 'Babymassage';
            break;
        case 'stillgruppe':
            title = 'Stillgruppe';
            break;
        case 'waldworkshop':
            title = 'Wald-Workshops';
            break;
        default:
    }

    return (<>
        <div style={{ backgroundColor: '#9E5288', color: '#fff' }}>
            {!isMobile && <img className="angebote-bild-desktop" src={"/" + angebot + ".jpg"} />}
            <p style={{ fontSize: '3em', marginBottom: 0, padding: '50px 30px 30px 30px' }}>{title}</p>
        </div>
        <div className="content-container">
            <div style={{ fontSize: '16pt', fontFamily: 'Yantramanav', color: '#5F3159', textAlign: 'justify' }}>
                {Texte[angebot]}
            </div>
            {isMobile && <img className="angebote-bild-mobile" src={"/" + angebot + ".jpg"} />}
        </div>
        <Termine filter={angebot} />
    </>
    );
}
