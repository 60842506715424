import ReCAPTCHA from "react-google-recaptcha";
import { Form, Col, Row, Input, Button } from "antd";
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import axios from "axios";
const { TextArea } = Input


export function Kontakt() {

    const [verschickt, setVerschickt] = useState(false)

    const handleSubmit = (data) => {
        var axiosData = {
            method: 'POST',
            url: '/v2/kontakt/',
            data,
            // headers: authHeader(),
            json: true
        };
        axios(axiosData)
            .then(response => {
                if (typeof response.data === "object")
                    setVerschickt(true)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const onChange = (aaa) => {
        console.log(aaa)
    }
    return (
        <div style={{ backgroundColor: '#782A5E', padding: '30px' }}>
            <div className="textbox">
                <div className="text-center" style={{ fontSize: '3em', fontFamily: 'Yantramanav', color: '#fff' }}>Kontakt zu mir</div>
                {!verschickt ? <>
                    <div className="text-center" style={{ fontSize: '1.6em', fontFamily: 'Yantramanav', color: '#fff' }}>
                        Solltest Du Fragen haben oder wenn ich dir irgendwie behilflich sein kann, zögere nicht, mich zu kontaktieren.
                    </div>
                    <div className="text-center" style={{ paddingTop: '20px', paddingBottom: '20px', fontSize: '1.6em', fontFamily: 'Yantramanav', color: '#fff' }}>
                        <a style={{ color: '#fff' }} href="https://wa.me/4917672351608">Kontakt über WhatsApp <br /><u style={{ fontSize: '1.2em' }}><FontAwesomeIcon icon={faWhatsapp} style={{ marginLeft: '10px', marginRight: '10px' }} />+4917672351608</u></a>
                    </div>
                    <div className="text-center" style={{ fontSize: '1.6em', fontFamily: 'Yantramanav', color: '#fff' }}>
                        oder schreibe mir eine Nachricht:
                    </div>



                    <Row
                        justify="space-around"
                    >
                        <Col
                            xs={22}
                            sm={18}
                            md={16}
                            lg={8}
                        >

                            <Form
                                name="cf"
                                method="post"
                                onFinish={handleSubmit}
                                layout="vertical"
                            >
                                <Form.Item
                                    label={<font color="white">Name</font>}
                                    rules={[{ required: true, message: `Bitte gib deinen Namen ein` }]}
                                    name="name"
                                >
                                    <Input
                                        placeholder="Name"
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<font color="white">Telefon oder Handynummer</font>}
                                    rules={[{ required: true, message: `Bitte gib deine Telefonnummer an` }]}
                                    name="phone"
                                >
                                    <Input
                                        placeholder="Telefon / Handynummer"
                                        prefix={<PhoneOutlined className="site-form-item-icon" />}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<font color="white">E-Mail Adresse</font>}
                                    rules={[{ required: false, type: `email`, message: `Bitte gib eine eine gültige E-Mail Adresse ein` }]}
                                    name="email"
                                >
                                    <Input
                                        placeholder="E-Mail Adresse"
                                        prefix={<MailOutlined className="site-form-item-icon" />}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<font color="white">Nachricht</font>}
                                    rules={[{ required: true, message: `Es fehlt noch deine Nachricht` }]}
                                    name="message"
                                >
                                    <TextArea
                                        placeholder="Hier kannst du dein Anliegen schildern"
                                        rows={5}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button size="large" type="primary" htmlType="submit" disabled={false}>
                                        Kontakt aufnehmen
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>


                </> :
                <>
                    <div className="text-center" style={{ fontSize: '1.6em', fontFamily: 'Yantramanav', color: '#fff' }}>
                        Vielen Dank für deine Nachricht!
                    </div>
                </>}
            </div>
        </div>
    );
}
