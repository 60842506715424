const AngebotTexte = {
    stillberatung: (<>
        Unser Körper ist dafür gemacht unser Baby zu stillen. Das Stillen ist für unser Baby mehr als nur Nahrung. Es ist außerdem Nähe, Beruhigung, Geborgenheit, Bindung, Trost und vieles mehr. Es ist somit die Befriedigung jeglicher Grundbedürfnisse und stärkt das Urvertrauen.
        Doch in einigen Fällen funktioniert der Stillstart nicht so reibungslos, wie von der Natur vorgesehen. Dies kann verschiedene Ursachen haben, aber es bedeutet nicht, dass das Stillen nicht möglich ist! Die absolute Mehrheit der Mütter ist in der Lage das Baby zu stillen und so auch komplett zu ernähren.
    </>
    ),
    stillvorbereitung: (<>
    Durch eine Stillvorbereitung bereits in der Schwangerschaft können werdende Eltern umfangreich informiert werden und viele Mythen rund um das Thema Stillen beseitigt werden. So wird die Kompetenz der Eltern gestärkt und sie können die erste Zeit ohne Unsicherheiten mit ihrem Baby genießen.
    </>
    ),
    outdoorfit: (<>
    Ich bin prä- und postnatale Fitnesstrainerin und habe mich auf Outdoorkurse mit Kind spezialisiert. Für diese Auszeit benötigst du keinen Babysitter. Dein Baby/ Kleinkind ist immer dabei, egal ob im Kinderwagen oder in der Trage.
Wir trainieren bei jedem Wetter an der frischen Luft. Das fördert nicht nur die körperliche Beweglichkeit, sondern auch die geistige Fitness. Zusätzlich stärken Übungen im Freien das Körperbewusstsein, die Sinne werden geschärft und die innere Balance wird aktiviert. Durch die im Gelände befindlichen Steigungen und holprigen Waldböden wird die gesamte Muskulatur gefördert und nebenbei die Aufmerksamkeit geschult.
    </>
    ),
    trageberatung: (<>
    Das Menschenkind ist ein Tragling. Für seine Entwicklung ist Nähe und Körperkontakt enorm wichtig. Denn Nähe und Körperkontakt sind ein Grundbedürfnis. Nähe und Körperkontakt sind Grundbedürfnisse und für seine Entwicklung und die Stärkung des Urvertrauens absolut wichtig. Durch das Tragen spürt es die Wärme, die schaukelnden Bewegungen und den Herzschlag des Tragenden. Diese Dinge kennt es bereits aus dem Mutterleib.
    </>
    ),
    elterncafe: (<>
    Das Elterncafé richtet sich an Mamas und Papas mit ihren Kindern bis zum 1. Geburtstag. Hier können Eltern sich austauschen und neue Kontakte knüpfen. Die Kinder erkunden und entdecken in der Zeit neue Spielmöglichkeiten und treten in Interaktion mit anderen Kindern.
<br/><br/>
Für Fragen zum Elterncafé kontaktiere mich!
    </>
    ),
    babymassage: (<>
    <i>„Die Hände bilden die erste Beziehung des Säuglings mit der Welt (außer dem Stillen). Hände heben ihn auf, legen ihn hin, waschen, kleiden, füttern ihn eventuell auch. Welcher Unterschied: Wie anders ist das Bild der Welt, das sich für den Säugling offenbart, wenn ruhige, geduldige, behutsame, aber doch sichere und entschlossene Hände mit ihm umgehen – und wie ganz verschieden gestaltet sich die Welt,  wenn diese Hände ungeduldig, derb oder hastig, unruhig und nervös sind. Am Anfang bedeuten für den Säugling die Hände alles, sie sind der Mensch, die Welt.“</i><br/>
- Emmi Pikler - <br/>
<br/>
Im Kurs Babymassage geht es um Entspannung für das Baby und auch den/ die Massierende/n. Durch die innigen Berührungen wird die Bindung gestärkt und wir helfen dem Baby seinen eigenen Körper zu spüren und zu erfassen. Gleichzeitig können wir durch bestimmte Massagegriffe helfen Bauchschmerzen vorzubeugen oder zu lindern und die Verdauung anzuregen.<br/><br/>
Für Fragen zum Kurs kontaktiere mich!
    </>
    ),
    stillgruppe: (<>
    In der Stillgruppe treffen wir uns in regelmäßigen Abständen mit Gleichgesinnten und tauschen uns zum Thema Stillen aus. Wir reden über ein zentrales Thema oder besprechen Fragen und/ oder Anliegen, die ihr mitbringt.<br/><br/>
Für Fragen zur Stillgruppe kontaktiere mich!
    </>
    ),
    waldworkshop: (<>
    Mit Kindern Zeit in der Natur zu verbringen und zu sehern wie wohl sie sich dort fühlen ist ein tolles Gefühl. Der Wald bietet Kindern unheimlich viele Möglichkeiten kreativ zu spielen und sich frei zu fühlen.
    </>
    )
}

export default AngebotTexte;