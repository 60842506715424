
export function Impressum() {
    return (
        <div className="content-container">
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Janina Schnaubelt-Gronau<br />
                NeuesBauchgef&uuml;hl<br />
                Genter Stra&szlig;e 89<br />
                46147 Oberhausen</p>

            <h2>Kontakt</h2>
            <p>Telefon: 017672351608<br />
                E-Mail: info@neues-bauchgefuehl.de</p>

            <h2>Redaktionell verantwortlich</h2>
            <p>Janina Schnaubelt-Gronau<br />
                Genter Stra&szlig;e 89<br />
                46147 Oberhausen</p>

                <h2>Technische Umsetzung und Kontakt</h2>
            <p><a href="https://www.kempkens.media">
                <img alt="Logo kempkens.media" src="./logo_kempkens_media.png" style={{ backgroundColor: 'white', float: 'left', padding: '5px', margin: '5px', width: '200px' }} />

            </a>
                <b>kempkens.media</b><br />
                <font size="-1">entwicklung + design + hosting<br />
                    Weitere Informationen unter <a href="www.kempkens.media">www.kempkens.media</a></font>
            </p>
            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

            <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            <br /><br />



        </div>
    );
}
