import { useEffect, useState } from "react";
import axios from 'axios';
import moment from "moment";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input } from "antd";
import { Termine } from "./Termine";

export function Admin(props) {
    const [termine, setTermine] = useState([])
    const [loggedIn, setLoggedIn] = useState(false)
    const [loginError, setLoginError] = useState(false)

        const {filter } = props;
    useEffect(() => {
        if(localStorage.getItem('admin')) setLoggedIn(true);
        else setLoggedIn(false);    
    }, [])
    


    const onFinish = (values: any) => {
        var axiosData = {
            method: 'POST',
            url: '/v2/login',
            data: {
                ...values
            },
            // headers: authHeader(),
            json: true
        };
        axios(axiosData)
            .then(response => {
                if (response.data?.login === "ok") {
                    localStorage.setItem('admin',response.data.token)
                    setLoggedIn(true);
                    setLoginError(false);
                } else {
                    setLoginError(true);
                }
            })
            .catch((error) => {
                setLoginError(true);
                console.error(error)
            })    

      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
    
    return (<>
        <div style={{ backgroundColor: '#9E5288', color: '#fff' }}>
            <p style={{ fontSize: '2em', marginBottom: 0, padding: '10px', paddingLeft: '30px' }}>Administration</p>
        </div>
        {!loggedIn ? <div style={{padding: '100px'}}>
            {loginError && <Alert style={{margin: '50px'}} type="error" showIcon message="Benutzername oder Passwort falsch" />}
        <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{  }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Benutzer"
        name="username"
        rules={[{ required: true, message: 'Bitte Benutzername eingeben!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Passwort"
        name="password"
        rules={[{ required: true, message: 'Bitte Passwort eingeben!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Einloggen
        </Button>
      </Form.Item>
    </Form>
        </div>
        : 
        <>
        <div style={{margin: '20px'}}>  
        <Termine admin={true} />
        </div>
        </>}

    </>
    );
}
