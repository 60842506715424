import React, { useEffect, useState } from "react";
import axios from 'axios';
import moment from "moment";
import { Link } from "react-router-dom";
import { Alert, Button, DatePicker, Descriptions, Drawer, Input, Modal, Select, Switch } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;


const nl2br = (str) => {
    var newlineRegex = /(\r\n|\r|\n|\\n)/g;
    if (typeof str !== 'string') {
      return str;
    }
  
    return str.split(newlineRegex).map(function (line, index) {
      if (line.match(newlineRegex)) {
        return React.createElement('br', { key: index });
      }
      return line;
    });
  };

export function Termine(props) {
    const [termine, setTermine] = useState([])
    const [newTerminModal, setNewTerminModal] = useState(false)
    const [editTerminModal, setEditTerminModal] = useState(false)
    const [editTerminAngebot, setEditTerminAngebot] = useState('')
    const [editTerminName, setEditTerminName] = useState('')
    const [editTerminDatum, setEditTerminDatum] = useState('')
    const [editTerminZeit, setEditTerminZeit] = useState('')
    const [editTerminHinweisRot, setEditTerminHinweisRot] = useState('')
    const [editTerminHinweisGelb, setEditTerminHinweisGelb] = useState('')
    const [editTerminBeschreibung, setEditTerminBeschreibung] = useState('')

    const { filter } = props;

    const loadData = () => {
        var axiosData = {
            method: 'POST',
            url: '/v2/termine/read',
            data: {
                angebot: filter || undefined
            },
            // headers: authHeader(),
            json: true
        };
        axios(axiosData)
            .then(response => {
                if (typeof response.data === "object")
                    setTermine(response.data.content)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        loadData()
        return () => { }
    }, [props.filter])

    const authHeader = (plain = false) => {
        // return authorization header with jwt token
        let token = localStorage.getItem('admin');
        if (plain) {
            return token;
        } else {
            if (token) {
                return { 'Authorization': 'Bearer ' + token };
            } else {
                return {};
            }
        }
    }

    const deleteTermin = (id) => {
        var axiosData = {
            method: 'DELETE',
            url: '/v2/termine/' + parseInt(id),
            headers: authHeader(),
            json: true
        };
        axios(axiosData)
            .then(response => {
                loadData();
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const saveChanges = (id) => {
        var axiosData = {
            method: 'PATCH',
            url: '/v2/termine/' + id,
            headers: authHeader(),
            data: {
                Angebot: editTerminAngebot,
                Name: editTerminName,
                Datum: moment(editTerminDatum).format("YYYY-MM-DD"),
                Zeit: editTerminZeit,
                HinweisRot: editTerminHinweisRot,
                HinweisGelb: editTerminHinweisGelb,
                Beschreibung: editTerminBeschreibung,
            },
            json: true
        };
        axios(axiosData)
            .then(response => {
                loadData();
                setEditTerminModal(false)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const saveNew = (id) => {
        var axiosData = {
            method: 'POST',
            url: '/v2/termine/create',
            headers: authHeader(),
            data: {
                Angebot: editTerminAngebot,
                Name: editTerminName,
                Datum: moment(editTerminDatum).format("YYYY-MM-DD"),
                Zeit: editTerminZeit,
                HinweisRot: editTerminHinweisRot,
                HinweisGelb: editTerminHinweisGelb,
                Beschreibung: editTerminBeschreibung,
            },
            json: true
        };
        axios(axiosData)
            .then(response => {
                loadData();
                setNewTerminModal(false)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const renderEditDrawer = () => (
        <Drawer visible={editTerminModal} placement="right" width={'80vw'} title="Termin anpassen" onClose={() => setEditTerminModal(false)}>
            <div style={{ fontSize: '14pt' }}>
                <Descriptions bordered column={1} layout="horizontal">
                    <DescriptionsItem label="Bezogen auf Angebot?">
                        <Select value={editTerminAngebot} onChange={val => setEditTerminAngebot(val)} style={{ width: '300px' }}>
                            <Option value="">(unabhängig)</Option>
                            <Option value="babymassage">Babymassage</Option>
                            <Option value="elterncafe">Elterncafé</Option>
                            <Option value="stillberatung">Stillberatung</Option>
                            <Option value="stillgruppe">Stillgruppe</Option>
                            <Option value="stillvorbereitung">Stillvorbereitung</Option>
                            <Option value="trageberatung">Trageberatung</Option>
                            <Option value="outdoorfit">Outdoor-Fit mit Kind</Option>
                            <Option value="waldworkshop">Wald-Workshops</Option>
                        </Select>
                    </DescriptionsItem>
                    <DescriptionsItem label="Name">
                        <Input value={editTerminName} onChange={(e) => setEditTerminName(e.target.value)} />
                    </DescriptionsItem>
                    <DescriptionsItem label="Datum / Zeit">

                        <DatePicker showToday format="DD.MM.YYYY" value={moment(editTerminDatum)} onChange={value => setEditTerminDatum(value)} />
                        <Input style={{ width: '300px' }} placeholder="Zeit (optional)" value={editTerminZeit} onChange={e => setEditTerminZeit(e.target.value)} /><br />
                    </DescriptionsItem>
                    <DescriptionsItem label="Hinweis (rot)">
                        <Input value={editTerminHinweisRot} onChange={e => setEditTerminHinweisRot(e.target.value)} />
                    </DescriptionsItem>
                    <DescriptionsItem label="Hinweis (gelb)">
                        <Input value={editTerminHinweisGelb} onChange={e => setEditTerminHinweisGelb(e.target.value)} />
                    </DescriptionsItem>
                    <DescriptionsItem label="Beschreibung">
                        <TextArea rows="10" value={editTerminBeschreibung} onChange={e => setEditTerminBeschreibung(e.target.value)} />
                    </DescriptionsItem>
                </Descriptions>
                <Button type="primary" onClick={() => saveChanges(editTerminModal.id)}>Speichern</Button>
                <br />

            </div>
        </Drawer>)

const renderNeuerTerminDrawer = () => (
    <Drawer visible={newTerminModal} placement="right" width={'80vw'} title="Neuer Termin" onClose={() => setNewTerminModal(false)}>
        <div style={{ fontSize: '14pt' }}>
            <Descriptions bordered column={1} layout="horizontal">
                <DescriptionsItem label="Bezogen auf Angebot?">
                    <Select value={editTerminAngebot} onChange={val => setEditTerminAngebot(val)} style={{ width: '300px' }}>
                        <Option value="">(unabhängig)</Option>
                        <Option value="babymassage">Babymassage</Option>
                        <Option value="elterncafe">Elterncafé</Option>
                        <Option value="stillberatung">Stillberatung</Option>
                        <Option value="stillgruppe">Stillgruppe</Option>
                        <Option value="stillvorbereitung">Stillvorbereitung</Option>
                        <Option value="trageberatung">Trageberatung</Option>
                        <Option value="outdoorfit">Outdoor-Fit mit Kind</Option>
                        <Option value="waldworkshop">Wald-Workshops</Option>
                    </Select>
                </DescriptionsItem>
                <DescriptionsItem label="Name">
                    <Input value={editTerminName} onChange={(e) => setEditTerminName(e.target.value)} />
                </DescriptionsItem>
                <DescriptionsItem label="Datum / Zeit">

                    <DatePicker showToday format="DD.MM.YYYY" value={moment(editTerminDatum)} onChange={value => setEditTerminDatum(value)} />
                    <Input style={{ width: '300px' }} placeholder="Zeit (optional)" value={editTerminZeit} onChange={e => setEditTerminZeit(e.target.value)} /><br />
                </DescriptionsItem>
                <DescriptionsItem label="Hinweis (rot)">
                    <Input value={editTerminHinweisRot} onChange={e => setEditTerminHinweisRot(e.target.value)} />
                </DescriptionsItem>
                <DescriptionsItem label="Hinweis (gelb)">
                    <Input value={editTerminHinweisGelb} onChange={e => setEditTerminHinweisGelb(e.target.value)} />
                </DescriptionsItem>
                <DescriptionsItem label="Beschreibung">
                    <TextArea rows="10" value={editTerminBeschreibung} onChange={e => setEditTerminBeschreibung(e.target.value)} />
                </DescriptionsItem>
            </Descriptions>
            <Button type="primary" onClick={() => saveNew()}>Erstellen</Button>
            <br />

        </div>
    </Drawer>)

    const editTermin = (el) => {
        setEditTerminName(el.Name);
        setEditTerminAngebot(el.Angebot);
        setEditTerminDatum(el.Datum);
        setEditTerminZeit(el.Zeit);
        setEditTerminHinweisRot(el.HinweisRot);
        setEditTerminHinweisGelb(el.HinweisGelb);
        setEditTerminBeschreibung(el.Beschreibung);
        setEditTerminModal(el)
    }

    const copyTermin = (el) => {
        setEditTerminName(el.Name);
        setEditTerminAngebot(el.Angebot);
        setEditTerminDatum(el.Datum);
        setEditTerminZeit(el.Zeit);
        setEditTerminHinweisRot(el.HinweisRot);
        setEditTerminHinweisGelb(el.HinweisGelb);
        setEditTerminBeschreibung(el.Beschreibung);
        setNewTerminModal(el)
    }

    const newTermin = () => {
        setEditTerminName('');
        setEditTerminAngebot('');
        setEditTerminDatum(moment().format('YYYY-MM-DD'));
        setEditTerminZeit('');
        setEditTerminHinweisRot('');
        setEditTerminHinweisGelb('');
        setEditTerminBeschreibung('');
        setNewTerminModal(true)
    }

    return (<>

        {(props.admin) ? renderEditDrawer() : ''}
        {(props.admin) ? renderNeuerTerminDrawer() : ''}
        

        {!props.admin ? <div style={{ backgroundColor: '#9E5288', color: '#fff' }}>
            <p style={{ fontSize: '2em', marginBottom: 0, padding: '10px', paddingLeft: '30px' }}>Die nächsten Termine</p>
        </div> : ''}
        {props.admin && <Button type="primary" onClick={() => newTermin()}>Neuer Termin</Button>}
        <div style={{ margin: '20px' }}>
            {termine.map((el, idx) => {
                return (
                    <div key={idx}>
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                            <div style={{ flexFlow: 'row' }}>
                                <div style={{ fontSize: '14pt' }}>
                                    {props.admin && <div>
                                        <Button size="small" type="primary" onClick={() => editTermin(el)}>Bearbeiten</Button>
                                        <Button size="small" type="secondary" onClick={() => copyTermin(el)}>Kopieren</Button>
                                        <Button size="small" type="danger" onClick={() => deleteTermin(el.id)}>Löschen</Button>
                                    </div>}
                                    {moment(el.Datum).format('DD.MM.YYYY')}: {el.Zeit}</div>
                                <div style={{ fontWeight: 'bold' }}>{el.Name}</div>
                                {el.HinweisRot && <Alert type="error" showIcon message={el.HinweisRot} />}
                                {el.HinweisGelb && <Alert type="warning" showIcon message={el.HinweisGelb} />}
                            </div>
                            <div>{nl2br(el.Beschreibung)}</div>
                            {el.Angebot && <div>Weitere Informationen zum Angebot finden Sie <Link to={'/angebote/' + el.Angebot}>hier</Link>.</div>}
                        </div>
                        <hr />
                    </div>
                )
            })}
            {termine.length === 0 && <div>Keine Termine</div>}
        </div>


    </>
    );

      
      
}
