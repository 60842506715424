
export function About() {
    return (
        <div className="row-container">
            <div className="about-textbox" style={{justifyItems: 'center'}}>
                <div style={{ fontSize: '30pt', fontFamily: 'Yantramanav', color: '#9E5288' }}>Hallo!</div>
                <div style={{ fontSize: '16pt', fontFamily: 'Yantramanav', color: '#5F3159', textAlign: 'justify' }}>
                    Ich bin Janina und wohne mit meinen zwei Töchtern in Oberhausen Schmachtendorf.
                    Durch meine Kinder und die mit ihnen gemachten Stillerfahrungen, habe ich gelernt auf
                    mein <b>Bauchgefühl</b> zu hören und darauf zu vertrauen. Mir wurde bewusst, wie wichtig eine
                    Stillberatung ist und ich entschloss mich eine <b>DAIS Stillbegleiterin</b> zu werden. Auch
                    merkte ich schnell, wie sehr v.a. Mütter in der ersten Zeit mit ihren Babies Unterstützung,
                    Bestärkung und Austausch brauchen. So machte ich die Ausbildung zur <b>prä- und postnatalen
                    Fitnesstrainierin</b> bei superMAMAfitness. Hier liegt mein Fokus auf dem Outdoorkurs mit dem
                    Schwerpunkt Beckenboden. Um mein Angebot komplett zu machen, machte ich eine Ausbildung bei
                    der <b>Trageschule NRW</b> und kann Eltern qualifiziert zum Thema Tragen beraten.
                </div>
            </div>
            <div>
                <img className="fullimg" src="./janina.jpg" />
            </div>
        </div>
    );
}
